/**
 * @fileOverview
 * todo 企服商城订单列表页面接口
 * @author xiaoF
 * @since 2021/3/30.
 */
import store from "@/store";
import http from '@/utils/axios';

let enterprise = '/api/enterprise'; // 企业
let URL = {
 // 获取列表数据
 STAFFAPPLY_LIST: enterprise + '/iyou/staff/staffApplyList',
 // 企业用户申请列表审批
 VERIFY_STAFF: enterprise + '/staffEntrprise/verifyStaff',
 // 查看用户申请详情
 STAFFENTRPRISE: enterprise + '/staffEntrprise',// 获取所属部门tree
 GET_COMPANY_ARCHITECTURE: enterprise + '/department/list',
}
// 获取列表
export function staffApplyList( data) {
  return http({
    url: URL.STAFFAPPLY_LIST,
    method: 'POST',
    data: {
      ...data,
      'role_id':store.state.common.currentMenuRoleId
    }
  })
}

// 查看审批详情
export function staffentrPrise(id) {
  return http({
    url: URL.STAFFENTRPRISE + '/' + id,
    method: 'GET'
  })
}

// 审批
export function verifystaff(data) {
  return http({
    url: URL.VERIFY_STAFF,
    method: 'POST',
    data: {
      ...data
    }
  })
}
// 获取组织
export function getCompanyArchitecture(companyId) {
  return http({
    url: URL.GET_COMPANY_ARCHITECTURE,
    method: 'GET',
    params: {
      'filter.name': 'departmentId_eq_or_enterpriseId_eq',
      'filter.params': `null,${companyId}`,
      'paging.page': 0,
      'paging.size': 1000
    }
  })
}
